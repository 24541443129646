body {
  box-sizing: border-box;
}

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::selection {
  background-color: #c300f0;
  color: white;
}
